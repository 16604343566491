<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card>
          <div class="d-flex align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2 d-flex justify-content-between">
              <span>{{ partner.title }}</span>
            </h3>
          </div>
          <hr>
          <b-tabs
            v-model="activeTab"
            class="h-scroll-tabs"
            nav-class="mb-3"
            pills
            @input="updateHash"
          >
            <!--            <b-tab-->
            <!--              class="text-left"-->
            <!--              href="Agreements"-->
            <!--              title="Agreements"-->
            <!--              lazy-->
            <!--            >-->
            <!--              $Lorem-->
            <!--            </b-tab>-->
            <b-tab
              class="text-left"
              href="Startups"
              lazy
              title="Startups"
            >
              <Startups />
            </b-tab>

            <b-tab
              class="text-left"
              href="Programs"
              lazy
              title="Programs"
            >
              <Programs />
            </b-tab>
            <!--            Tab for Events-->
            <b-tab
              class="text-left"
              href="Events"
              lazy
              title="Events"
            >
              <Events />
            </b-tab>
            <!--            Tab for Competitions -->
            <b-tab
              class="text-left"
              href="Competitions"
              lazy
              title="Competitions"
            >
              <Competitions />
            </b-tab>
            <!--            Tab for Skill Dev -->
            <b-tab
              class="text-left"
              href="SkillDev"
              lazy
              title="SDPs"
            >
              <Skill-dev />
            </b-tab>

            <b-tab
              class="text-left"
              href="Sessions"
              lazy
              title="Sessions"
            >
              <Emeet />
            </b-tab>
            <b-tab
              class="text-left"
              href="Requests"
              lazy
              title="Requests"
            >
              <Requests />
            </b-tab>
            <!-- <b-tab
              class="text-left"
              href="Feedbacks"
              lazy
              title="Feedbacks"
            >
              <Feedback />
            </b-tab> -->

          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'

import Requests from '@/views/incubator-portal/partners/pages/partner-manager/Requests.vue'
import Programs from '@/views/incubator-portal/partners/pages/partner-manager/Programs.vue'
import Startups from '@/views/incubator-portal/partners/pages/partner-manager/Startups.vue'
import Emeet from '@/views/incubator-portal/partners/pages/partner-manager/Emeet.vue'
import Events from '@/views/incubator-portal/partners/pages/partner-manager/Events.vue'
import SkillDev from '@/views/incubator-portal/partners/pages/partner-manager/SkillDev.vue'
// import Feedback from '@/views/incubator-portal/partners/pages/partner-manager/Feedback.vue'
import Competitions from '@/views/incubator-portal/partners/pages/partner-manager/Competitions.vue'
import gql from 'graphql-tag'

export default {
  components: {
    Competitions,
    // Feedback,
    SkillDev,
    Events,
    Emeet,
    Programs,
    Startups,
    Requests,
    BRow,
    BCol,
    BTab,
    BTabs,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        '#Startups',
        '#Programs',
        '#Events',
        '#Competitions',
        '#SkillDev',
        '#Sessions',
        '#Requests',
        '#Feedbacks'],
      partner: {},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    partner: {
      query() {
        return gql`
           query about_partner {
           users_organizationtable(where: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}) {
             title
          }
        }`
      },
      update: data => data.users_organizationtable[0],
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
